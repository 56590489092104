const component = '/menu/component/'
export const componentList = component + 'list'
export const componentStore = component + 'store'
export const componentUpdate = component + 'update'
export const componentToggle = component + 'toggle-status'

const modules = '/menu/module/'
export const moduleList = modules + 'list'
export const moduleStore = modules + 'store'
export const moduleUpdate = modules + 'update'
export const moduleToggle = modules + 'toggle-status'

const service = '/menu/service/'
export const serviceList = service + 'list'
export const serviceStore = service + 'store'
export const serviceUpdate = service + 'update'
export const serviceToggle = service + 'toggle-status'

const menu = '/menu/menu/'
export const menuList = menu + 'list'
export const menuStore = menu + 'store'
export const menuUpdate = menu + 'update'
export const menuToggle = menu + 'toggle-status'
